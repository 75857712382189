import React from "react";
import Placeholder from "../../../../core/Components/Placeholder";
import {
  calculateSpacing,
  getHorizontalAlign,
  getVerticalAlign,
  joinClasses,
} from "../../../../utils/getContainerClasses";
import useIsMobile from "../../../../utils/useIsMobile";
import buildBackgroundString from "../common/buildBackgroundString";
import MobileExternalBackground from "../common/MobileExternalBackground";

const TwoColumnContainer = ({ fields, rendering }) => {
  const isMobile = useIsMobile();

  const { backgroundString, hasPatternBackground } = buildBackgroundString({
    isMobile,
    fields,
  });

  return (
    <section
      id={fields.id?.value || undefined}
      className={joinClasses(
        "column-container",
        fields.backgroundColor?.name && `bg-${fields.backgroundColor.name}`,
        (fields.backgroundImageRtlFlip?.value || hasPatternBackground) &&
          "background-image-rtl-flip",
        fields.roundedCorners?.value && "rounded",
        fields.outerClassName?.value,
        calculateSpacing(fields),
      )}
      style={
        backgroundString &&
        !(fields.backgroundMobileExternalPosition?.name && isMobile)
          ? { background: backgroundString }
          : undefined
      }
    >
      <div className={fields.useContainer?.value ? "container" : undefined}>
        <div
          className={joinClasses(
            "row",
            fields.useContainer?.value && "gy-6 gy-md-0",
            fields.rowReverse?.value && "flex-md-row-reverse",
            getHorizontalAlign(fields.horizontalAlign?.name),
            getVerticalAlign(fields.verticalAlign?.name),
          )}
        >
          {isMobile &&
          backgroundString &&
          fields.backgroundMobileExternalPosition?.name === "before" ? (
            <MobileExternalBackground
              backgroundString={backgroundString}
              position="before"
            />
          ) : null}

          <div className={`col-md-${fields.col1?.value}`}>
            <Placeholder name="two-column-content-1" rendering={rendering} />
          </div>
          <div className={`col-md-${fields.col2?.value}`}>
            <Placeholder name="two-column-content-2" rendering={rendering} />
          </div>

          {isMobile &&
          backgroundString &&
          fields.backgroundMobileExternalPosition?.name === "after" ? (
            <MobileExternalBackground
              backgroundString={backgroundString}
              position="after"
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default TwoColumnContainer;
